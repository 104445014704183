/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CampPaginateResponse } from '../../models/camp-paginate-response';

export interface GetCampIndex$Params {

/**
 * Pagination offset
 */
  page?: number;

/**
 * Pagination limit
 */
  limit?: number;

/**
 * Pagination order field ascending
 */
  orderAsc?: 'id' | 'label' | 'name' | 'descriptionLink' | 'descriptionShort' | 'priority';

/**
 * Pagination order field descending
 */
  orderDesc?: 'id' | 'label' | 'name' | 'descriptionLink' | 'descriptionShort' | 'priority';

/**
 * Filter camp id
 */
  id?: number;

/**
 * Filter category id, comma separated values possible
 */
  category?: string;

/**
 * Filter gallery id, comma separated values possible
 */
  gallery?: string;

/**
 * Filter discountCampaign id, comma separated values not possible
 */
  discountCampaign?: string;

/**
 * Filter by name
 */
  name?: string;

/**
 * Filter by label
 */
  label?: string;

/**
 * Filter camp by allowed age
 */
  age?: number;

/**
 * Filter trip start
 */
  start?: string;

/**
 * Filter camp by trip duration
 */
  duration?: number;

/**
 * Filter CampRegion id
 */
  region?: string;

/**
 * Filter camp texts
 */
  query?: string;
}

export function getCampIndex(http: HttpClient, rootUrl: string, params?: GetCampIndex$Params, context?: HttpContext): Observable<StrictHttpResponse<CampPaginateResponse>> {
  const rb = new RequestBuilder(rootUrl, getCampIndex.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {});
    rb.query('limit', params.limit, {});
    rb.query('orderAsc', params.orderAsc, {});
    rb.query('orderDesc', params.orderDesc, {});
    rb.query('id', params.id, {});
    rb.query('category', params.category, {});
    rb.query('gallery', params.gallery, {});
    rb.query('discountCampaign', params.discountCampaign, {});
    rb.query('name', params.name, {});
    rb.query('label', params.label, {});
    rb.query('age', params.age, {});
    rb.query('start', params.start, {});
    rb.query('duration', params.duration, {});
    rb.query('region', params.region, {});
    rb.query('query', params.query, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CampPaginateResponse>;
    })
  );
}

getCampIndex.PATH = '/v1/camp';
